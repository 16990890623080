import React from 'react';

import Server from '../components/Server';
import Administration from '../components/Administration';

import '../styles/main.css';

function Main() {

	return (
        <div className="main">
            <Server />
            {/* <Trailer /> */}
            <Administration />
        </div>
    )
}

export default Main;
