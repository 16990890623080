import { useState, useEffect } from 'react';
import { Chart } from "react-google-charts";

import axios from 'axios';

import '../styles/admin.css';

function Admin() {

    const [transactions, setTransactions] = useState();
    const [purchases, setPurchases] = useState();
    const [chartData, setChartData] = useState();
    const [monthlyIncome, setMonthlyIncome] = useState();

    const[displayAll, setDisplayAll] = useState(false);

    useEffect(() => {

		axios({
			method: "get",
			url: `https://api.e-craft.pl/shop/all_transactions`
		}).then((result) => {           
            setTransactions(result.data);
		})

        axios({
			method: "get",
			url: `https://api.e-craft.pl/shop/all_purchases`
		}).then((result) => {         
            setPurchases(result.data);

            const preData = [];

            for(let i=0; i<14; i++) {
                var date = new Date();
                date.setDate(date.getDate() - i);
                let sum = 0;

                const localDate = date.toLocaleDateString();

                result.data.forEach(purchase => {
                    const x = new Date(purchase.data_sfinalizowania).toLocaleDateString();
                    if (x === localDate) {
                        sum += purchase.kwota_platnosci;
                    }
                })

                preData.push([
                    date.toLocaleDateString(),
                    sum
                ])
            }

            preData.push(["Data", "Przychód"]);
            setChartData(preData.reverse());

            let income = 0;

            const today = new Date();
            const month = today.getMonth() + "-" + today.getFullYear();

            result.data.forEach(purchase => {
                const x = new Date(purchase.data_sfinalizowania);
                if (x.getMonth() + "-" + x.getFullYear() === month)
                    income += purchase.kwota_platnosci;
            })

            setMonthlyIncome(income);
		})

    }, []);

    const handleButton = () => setDisplayAll(!displayAll);

    const options = {
        title: "Przychód (14 Ostatnich Dni)",
        titleTextStyle: {color: 'white'},
        curveType: "function",
        legend: { position: "bottom", color: 'white' },
        backgroundColor: {
            fill: 'rgb(20, 20, 20)'
        },
        chartArea: {
            backgroundColor: {
                fill: 'gray',
                fillOpacity: 0.1,
            }
        },
        hAxis: { textStyle: { color: 'white' } },
        vAxis: { textStyle: { color: 'white' } },
        colors: ['#fcb441']
    };

	return (
		<div className="admin">

            { chartData ?

            <div className="admin-chart">

                <p className="shop-item-header"> Wykres </p>

                <Chart
                    chartType="LineChart"
                    width="100%"
                    height="400px"
                    data={chartData}
                    options={options}
                />
            </div>
            
            : null }

            <br />

            <div className="admin-income">
                Przychód w tym miesiącu: <font color="red"> {monthlyIncome} zł </font>
            </div>

            <br />

            <button onClick={handleButton}>Przelacz Widok</button>

            <br />

            {displayAll ?
            
                <>
                {transactions ? (
                    <>
                        <p className="shop-item-header"> Wszystkie Transakcje </p>

                        <table>
                            <tbody>

                                <tr>
                                    <th>Id Transakcji </th>
                                    <th>Nick</th>
                                    <th>Usluga</th>
                                    <th>Typ Platnosci</th>
                                    <th>Kwota Platnosci</th>
                                    <th>Status</th>
                                    <th>Data Wygenerowania</th>
                                </tr>

                                {transactions.map((transaction) => {

                                    const data = new Date(transaction.data_wygenerowania);
                                    const x = data.toLocaleString();

                                    return (
                                        <tr key={transaction.uuid}>
                                            <td>{transaction.uuid}</td>
                                            <td>{transaction.nick}</td>
                                            <td>{transaction.usluga}</td>
                                            <td>{transaction.typ_platnosci}</td>
                                            <td>{transaction.kwota_platnosci}</td>
                                            <td>{transaction.status}</td>
                                            <td> {x}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </>
                    )
                : null}
                </>

            : 

                            <>
                {purchases ? (
                    <>
                        <p className="shop-item-header"> Zaplacone Transakacje </p>

                        <table>
                            <tbody>

                                <tr>
                                    <th>Id Transakcji </th>
                                    <th>Nick</th>
                                    <th>Usluga</th>
                                    <th>Typ Platnosci</th>
                                    <th>Kwota Platnosci</th>
                                    <th>Data Zaplaty</th>
                                </tr>

                                {purchases.map((purchase) => {

                                    const data = new Date(purchase.data_sfinalizowania);
                                    const x = data.toLocaleString();

                                    return (
                                        <tr key={purchase.uuid}>
                                            <td>{purchase.uuid}</td>
                                            <td>{purchase.nick}</td>
                                            <td>{purchase.usluga}</td>
                                            <td>{purchase.typ_platnosci}</td>
                                            <td>{purchase.kwota_platnosci}</td>
                                            <td> {x}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </>
                    )
                : null}
                </>
            
            }
		</div>
	);
}

export default Admin;
