import React from 'react';

import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const SuccessStatus = () => {
    return ( 
        <>
            <div className="payment-status-container-card-ok">
                <FAI icon={faCircleCheck} />
            </div>
            
            <div className="payment-status-container-card-info"> 
                <p>Sukces!</p>
                Twoje zamówienie zostało sfinalizowane!
                <br />
                <br />
                Twoja usługa właśnie została nadana. <br />
                Dziękujemy za wspieranie naszego serwera!
            </div>
        </>
     );
}
 
export default SuccessStatus;