import '../styles/credits.css';

function Credits() {

	return (
		<div className="credits">
            <h1>Twórcy</h1>
            <p>xN0MANDx (Rafał Aniszewski) - Właściciel, Programista
            <br />
            KGaZ (Jakub Gałgan) - Administrator, Programista </p>
		</div>
	);
}

export default Credits;
