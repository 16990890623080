import React from 'react';

import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const UnknownStatus = () => {
    return ( 
        <>
            <div className="payment-status-container-card-unknown">
                <FAI icon={faCircleQuestion} />
            </div>

            <div className="payment-status-container-card-info"> 
                <p>Nieznany status!</p>
                <br />
                <br />
                Coś prawdopodobnie poszło nie tak... <br />
                Spróbuj ponownie lub skontaktuj się z administratorem.
            </div>
        </>
     );
}
 
export default UnknownStatus;