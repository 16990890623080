import '../../styles/rules.css';

import { NavLink } from 'react-router-dom';

function ServerRules() {
	return (
		<div className="rules">

                  <NavLink to="/sklep/regulamin"> <p className="rules-link"> ZOBACZ REGULAMIN SKLEPU </p> </NavLink>

                  <p className="rules-title"> Regulamin Serwera </p>

                  <p className="rules-header"> 1. Definicje </p>

                  <p className="rules-text">
                  a) Użytkownik - Osoba korzystająca z usług e-craft.pl. <br />
                  b) Administracja - Grupa osób zajmująca się serwerami e-craft.pl. <br />
                  c) Konto - Profil przeznaczony do gry jedynie przez użytkownika który je założył. <br />
                  d) Właściciel - Osoba o pseudonimie "xN0MANDx" aka "Nomand". <br />
                  </p>

                  <p className="rules-header"> 2. Postanowienia Ogólne </p>

                  <p className="rules-text">
                  a) Regulamin serwera w każdej chwili może ulec zmianom bez potrzeby informowania użytkowników. <br />
                  b) Każdy użytkownik ma obowiązek przestrzegać regulaminu. <br />
                  c) Użytkownik akceptuje regulamin w momencie dołączenia do serwera. <br />
                  d) Pożyczasz przedmioty na własną odpowiedzialność, lecz możesz umówić się z graczem na tzw. regułkę, wtedy administrator <br />
                    w razie kradzieży musi ci oddać przedmiot i ukarać gracza, który cię okradł. <br />
                    Przykładowo "Pożyczam Ci (?) na okres (?) bądź jeśli poproszę Cię o oddanie. <br />
                    Jeśli osoba napisze "tak", "dobra" i tym podobne, regułka będzie po prostu działać. <br /> 
                    Jeżeli osoba, będzie specjalnie Cię unikać, skontaktuj się z administracją a ona odda Ci przedmiot. <br />
                  </p> 

                  <p className="rules-header"> 3. Zakazy Użytkownika </p>

                  <p className="rules-text">
                  a) Zakaz zbędnego tworzenia kont itp. <br />
                  b) Zakaz tworzenia niestosownych nazw: kont, gildii, zwierzaków itp. <br />
                  c) Zakaz obrażania oraz prowokowania użytkowników. <br />
                  d) Zakaz wykorzystywania błędów i luk na serwerach. <br />
                  e) Zakaz działania na szkode dowolnych użytkowników i serwera. <br />
                  f) Zakaz tworzenia spamu, pisania wszystkiego dużymi literami oraz floodowania (np: "XDDDDDD"). <br />
                  g) Zakaz reklamowania rzeczy nie będących własnością e-craft bez pozwolenia. <br />
                  h) Zakaz zarabiania na oszukiwaniu użytkowników. <br />
                  i) Zakaz oddawania swojego konta innym użytkownikom. <br />
                  j) Zakaz włamywania się/wchodzenia na konta innych użytkowników. <br />
                  k) Zakaz używania wulgarnego słownictwa przy innych użytkownikach. <br />
                  l) Zakaz grania na macro będąc z dala od urządzenia. <br />
                  m) Zakaz posiadania macro powyżej 12 cps (kliknięć na sekundę). <br />
                  n) Zakaz próbowania złamania zabezpieczeń serwera. <br />
                  o) Zakaz używania klientów wspomagających grę i wszelkich innych wspomagaczy (cheaty, skrypty, zewnętrzne programy itp). <br />
                  p) Zakaz tworzenia fałszywych informacji o dowolnych podmiotach. <br />
                  q) Zakaz podawania danych osobowych bez zezwolenia ich właściciela. <br />
                  r) Zakaz pisania wiadomości niszczących porządek typu "serwer upada", "kończę z serwerem", "sprzedam konto" itp. <br />
                  s) Zakaz utrudniania gry innym użytkownikom. <br />
                  t) Wszystko co masz na serwerze jest własnością e-craft i zakazuje się wymian oraz zarabiania na tym poza serwerem. <br />
                  u) Zakaz oddawania swoich monet, itemów oraz sprzedawaniu ich za bezcen lub ułamek wartości. <br />
                  v) Zakaz używania artefaktów na afk. <br />
                  w) Zakaz pożyczania najlepszych zbroi, broni, bonów. Tyczy się to również sprzedawania, a potem odsprzedawania z powrotem i innych różnych prób omijania tej zasady. <br />
                  x) Zakaz robienia magazynu z targu. <br />
                  y) Nie nadużywaj /zglos. <br />
                  </p>

                  <p className="rules-header"> 4. Obowiązki Użytkownika </p>

                  <p className="rules-text">
                  a) Użytkownik jest zobowiązany zgłaszać wszelkie błędy, luki oraz innych użytkowników łamiących regulamin. <br />
                  b) Użytkownik musi współpracować z administracją. <br />
                  </p>

                  <p className="rules-header"> 5. Postanowienia Końcowe </p>

                  <p className="rules-text">
                  a) Właściciel zastrzega sobie prawo do wszelkich zmian w regulaminie. <br />
                  b) Wszelkie łamanie regulaminu będzie stosownie karane względem nagięć. <br />
                  c) Nieznajomość regulaminu nie zwalnia z odpowiedzialności. <br />
                  </p>
		</div>
	);
}

export default ServerRules;
