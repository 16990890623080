import React, { useEffect } from 'react';
import { SpinnerDiamond } from 'spinners-react';

function RedirectPage({ link }) {

    useEffect(() => {
        window.location.replace(link);
    }, [link])

	return (
        <div className="shop-redirect">
            <p>
                <SpinnerDiamond size="5em" color="#d2003b" speed="100" />
            </p>
            Za chwilę zostaniesz przeniesiony...
        </div>
	);
}

export default RedirectPage;
