import React from 'react';

import { FontAwesomeIcon as FAI } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const FailureStatus = () => {
    return ( 
        <>
            <div className="payment-status-container-card-fail">
                <FAI icon={faCircleXmark} />
            </div>

            <div className="payment-status-container-card-info"> 
                <p>Ups!</p>
                Twoje zamówienie zostało odrzucone!
                <br />
                <br />
                Coś prawdopodobnie poszło nie tak... <br />
                Spróbuj ponownie lub skontaktuj się z administratorem.
            </div>
        </>
     );
}
 
export default FailureStatus;